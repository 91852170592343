import React from 'react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

import {renderSwitch} from '../../utils/react'
import {defined} from '../../utils/variableEvaluation'
import styles from './Button.module.scss'

export enum ButtonTypes {
   Standard = 'Standard',
   Text = 'Text',
   Icon = 'Icon'
}

export enum ButtonStyles {
   Primary = 'Primary',
   Secondary = 'Secondary',
   Sticky = 'Sticky'
}

export enum ButtonSizes {
   Auto = 'Auto',
   Full = 'Full',
   ExtraSmall = 'ExtraSmall',
   Small = 'Small',
   Medium = 'Medium',
   Large = 'Large',
   ExtraLarge = 'ExtraLarge'
}

export enum ButtonColors {
   Action = "Action",
   Error = "Error",
   White = "White",
   WhiteInvert = "WhiteInvert",
}

enum Actions {
   InternalLink = 'InternalLink',
   ExternalLink = 'ExternalLink',
   NormalButton = 'NormalButton'
}

interface IProps {
   type?: ButtonTypes;
   style?: ButtonStyles;
   color?: ButtonColors;
   size?: ButtonSizes;
   to?: string;
   href?: string;
   isLoading?: boolean;
   isDisabled?: boolean;
   submit?: boolean;
   className?: string;
   onClick?: any;
   focusable?: boolean;
   icon?: any;
   disableLabel?: any;
   id?: string;
}

export class Button extends React.Component<IProps> {
   private action(): string {
      if (defined(this.props.to)) {
         return Actions.InternalLink
      } else if (defined(this.props.href)) {
         return Actions.ExternalLink
      } else {
         return Actions.NormalButton
      }
   }

   private renderButton() {
      const {
         isDisabled,
         isLoading,
         type,
         to,
         href,
         children,
         className,
         onClick,
         submit,
         focusable,
         icon,
         color,
         size,
         style,
         disableLabel,
         id
      } = this.props

      const buttonClass = classNames(className, {
         [styles.standardButton]: type === ButtonTypes.Standard,
         [styles.textButton]: type === ButtonTypes.Text,
         [styles.iconButton]: type === ButtonTypes.Icon,

         [styles.primaryButton]: style === ButtonStyles.Primary,
         [styles.secondaryButton]: style === ButtonStyles.Secondary,
         [styles.stickyButton]: style === ButtonStyles.Sticky,

         [styles.action]: color === ButtonColors.Action || !color,
         [styles.error]: color === ButtonColors.Error,
         [styles.white]: color === ButtonColors.White,
         [styles.whiteInvert]: color === ButtonColors.WhiteInvert,

         [styles.extraSmall]: size === ButtonSizes.ExtraSmall,
         [styles.small]: size === ButtonSizes.Small,
         [styles.medium]: size === ButtonSizes.Medium,
         [styles.large]: size === ButtonSizes.Large,
         [styles.extraLarge]: size === ButtonSizes.ExtraLarge,
         [styles.auto]: size === ButtonSizes.Auto,
         [styles.full]: size === ButtonSizes.Full,

         [styles.disabled]: isDisabled,
         [styles.icon]: icon,
      });

      return renderSwitch(this.action(), {
         [Actions.InternalLink]: () => (
            <Link
               key={`Link-${to}`}
               to={(to as string)}
               className={buttonClass}
               role="button"
            >
               {children}
            </Link>
         ),
         [Actions.ExternalLink]: () => (
            <a
               tabIndex={0}
               key={`a-${href}`}
               href={href}
               className={buttonClass}
               role="button"
               target="_blank"
               rel="noopener noreferrer"
            >
               {children}
            </a>
         ),
         [Actions.NormalButton]: () => (
            <button
               id={id}
               tabIndex={focusable == false ? -1 : 0}
               key="button"
               className={buttonClass}
               disabled={isDisabled}
               onClick={onClick}
               type={submit ? 'submit' : 'button'}
            >
               {children}
               {/*{this.props.isLoading ? <Spinner/> : children}*/}
               {icon && <div className={styles.icon}>{icon}</div>}
               {isDisabled && disableLabel && <div className={styles.disableLabel}>{disableLabel}</div>}
            </button>
         )
      })
   }

   public render() {
      return this.renderButton()
   }
}
